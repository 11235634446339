import {
	Box,
	Button,
	Divider,
	IconButton,
	Input,
	Typography,
	styled,
} from "@mui/joy";
import logo from "../assets/logo.png";
import Header from "../Components/Header";
import {
	ChevronLeft,
	ChevronRight,
	Mail,
	Phone,
	PinDrop,
	PlayArrowOutlined,
	Upload,
} from "@mui/icons-material";
import Partners from "../Components/Partners";
import Footer from "../Components/Footer";
import herobackground from "../assets/clubbanner.gif";
import phone from "../assets/phone1.png";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { useEffect, useMemo, useRef, useState } from "react";
import NewsLetter from "../Components/NewsLetter";
import IdentifyBookingSystem from "../Components/IdentifyBookingSystem";
import ImageCarousel from "../Components/ImageCarousel";
import ImageUploadModal from "../Components/ImageUploadModal";
import axios from "axios";
import useStore from "../admin/Stores";
import { validateEmail } from "../utils";
import FeesAndMemberships from "../Components/FeesAndMemberships";
import SquashFacilities from "../Components/SquashFacilities";
import VenueFacilities from "../Components/VenueFacilities";
import Programming from "../Components/Programming";
import LoginModal from "../Components/LoginModal";

const Img = styled("img")();

const FacilityInfo = ({ title, value }: any) => (
	<Box
		sx={{
			display: {
				xs: "block",
				sm: "flex",
			},
			mb: 1,
			alignItems: "center",
			flex: "25%",
		}}>
		<Box
			sx={{
				background: " #c4c4c4",
				width: {
					xs: "100%",
					sm: 200,
				},
				height: 40,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<Typography>{title}</Typography>
		</Box>

		<Typography ml={3} fontWeight={900}>
			{value}
		</Typography>
	</Box>
);

export default function Clubsites() {
	const store = useStore();
	const [venue, setVenue] = useState<any>(null);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [images, setImages] = useState<any>([]);
	const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
	const [texts, setTexts] = useState({
		facilityInfo: "",
	});

	const sliderRef = useRef<Slider>(null);
	const homeRef = useRef<HTMLDivElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);
	const contactUsRef = useRef<HTMLDivElement>(null);

	const venueName = useParams<{ venueName: string }>().venueName;

	useEffect(() => {
		const fetchVenue = async () => {
			const response = await fetch(
				`https://squash-search.ctoninja.tech/venues/get-by-name/${venueName}`
			);
			const data = await response.json();

			setVenue(data);
		};

		fetchVenue();
	}, [venueName]);

	useEffect(() => {
		const fetchImages = async () => {
			const response = await fetch(
				`https://backendapi.activscout.com/club-images/?club_name_unique=${venue?.name_unique}&status=active`
			);
			const data = await response.json();
			if (data.total === 0) return;
			setImages(data.data);
		};

		if (venue) fetchImages();
	}, [venue]);

	useEffect(() => {
		fetchFacilityInfo();
	}, []);

	const fetchFacilityInfo = async () => {
		try {
			const venueName = window.location.pathname.split("/")[2];
			const data = await store.app.service("club-texts").find({
				query: { club_name_unique: venueName },
			});

			if (data.total) {
				setTexts(data.data[0]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const goToSlide = (index: number) => {
		setCurrentSlide(index);
		sliderRef.current?.slickGoTo(index);
	};

	const handleSubmit = async () => {
		const data = {
			name,
			email,
			subject,
			message,
		};

		if (!name || !email || !subject || !message)
			return alert("Please fill all fields");

		if (!validateEmail(email)) return alert("Please enter a valid email");
		await fetch("https://backendapi.activscout.com/contact-us", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		setName("");
		setEmail("");
		setSubject("");
		setMessage("");

		alert("Message sent successfully");
	};

	const isMobile = useMemo(() => window.innerWidth < 768, []);
	const isTablet = useMemo(() => window.innerWidth < 900, []);
	const accessFields = [
		{
			title: "MEMBERS ONLY?",
			value: venue?.member_only === "Yes" ? "YES" : "NO",
		},
		{
			title: "GUESTS WELCOME",
			value: venue?.guest_welcome === "Yes" ? "YES" : "NO",
		},
		{
			title: "PAY & PLAY",
			value: venue?.pay_play === "Yes" ? "YES" : "NO",
		},
	];

	const courtsFields = [
		{
			title: "Total Courts",
			value: `${venue?.no_of_courts} courts`,
		},
		{
			title: "Single Courts",
			value: venue?.no_of_singles_courts + " courts",
		},
		{
			title: "Glass Courts",
			value: venue?.no_of_glass_courts + " courts",
		},
		{
			title: "Outdoor Courts",
			value: venue?.no_of_outdoor_courts + " courts",
		},
		{
			title: "Double Courts",
			value: venue?.no_of_doubles_courts + " courts",
		},
		{
			title: "Hardball Doubles Courts",
			value: venue?.no_of_hardball_doubles_courts + " courts",
		},
	];
	const clubFeaturesFields = [
		{
			title: "Sports Shop",
			value: venue?.sports_shop === "Yes" ? "YES" : "NO",
		},
		{
			title: "Hot Showers",
			value: venue?.hot_shower === "Yes" ? "YES" : "NO",
		},
		{
			title: "Parking",
			value: venue?.parking === "Yes" ? "YES" : "NO",
		},
		{
			title: "GYM",
			value: venue?.gym === "Yes" ? "YES" : "NO",
		},
		{
			title: "Multi-Sport Venue",
			value: venue?.multi_sport_venue === "Yes" ? "YES" : "NO",
		},
		{
			title: "Cafe",
			value: venue?.cafe === "Yes" ? "YES" : "NO",
		},
		{
			title: "Serves Alcohol",
			value: venue?.serves_alcohol === "Yes" ? "YES" : "NO",
		},
		{
			title: "Water Fountain",
			value: venue?.water_fountain === "Yes" ? "YES" : "NO",
		},
		{
			title: "Vending Machine",
			value:
				(venue?.drink_vending_machine || venue?.snack_vending_machine) === "Yes"
					? "YES"
					: "NO",
		},
		{
			title: "Online Booking",
			value: venue?.online_booking === "Yes" ? "YES" : "NO",
		},
		{
			title: "24hr Access",
			value: venue?.twentyfour_hour_access === "Yes" ? "YES" : "NO",
		},
		{
			title: "Permanently Staffed",
			value: venue?.permanently_staffed === "Yes" ? "YES" : "NO",
		},
		{
			title: "Re-stringing Service",
			value: venue?.re_stringing_service === "Yes" ? "YES" : "NO",
		},
	];

	return (
		<div>
			<Box
				ref={homeRef}
				sx={{
					width: "100%",
					height: "700px",
					position: "relative",
				}}>
				<img
					src={herobackground}
					alt="hero"
					style={{
						width: "100%",
						height: "100%",
						position: "absolute",
						zIndex: -1,
					}}
				/>
				<Header
					aboutRef={aboutRef}
					teamRef={null}
					contactUsRef={contactUsRef}
				/>

				<Box
					sx={{
						width: {
							xs: "90%",
							sm: "50%",
						},
						mx: {
							xs: "auto",
							md: 10,
						},
						mt: {
							xs: 5,
							sm: 10,
						},
					}}>
					<Typography
						sx={{
							color: "black",
							fontWeight: "600",
							fontSize: {
								xs: 38,
								sm: 40,
							},
							mb: 2,
						}}>
						MEADOW PARK RECREATION CENTRE
					</Typography>
					<Typography
						fontSize={18}
						sx={{
							color: "#7f7f7f",
						}}>
						8625 BC-99, Whistler BC V8E 1K1, Canada
					</Typography>

					<Button
						onClick={() => window.open(venue?.g_map_url, "_blank")}
						sx={{
							mt: 3,
							width: 200,
							height: 35,
							backgroundColor: " #a40e4c",
						}}>
						<Typography
							level="title-sm"
							sx={{ color: "white", fontWeight: "bold" }}>
							GOOGLE MAPS
						</Typography>
					</Button>

					{venue?.website && (
						<div>
							<Button
								onClick={() => window.open(venue.website, "_blank")}
								sx={{
									mt: 3,
									width: 200,
									height: 35,
									backgroundColor: "white",
								}}>
								<Typography
									level="title-sm"
									sx={{ color: "#312f9e", fontWeight: "bold" }}>
									OFFICIAL WEBSITE
								</Typography>
							</Button>
						</div>
					)}
				</Box>
				{/* <img
					src={logo}
					alt="logo"
					width={125}
					height={125}
					style={{
						position: "absolute",
						bottom: -140,
						left: "50%",
						transform: "translateX(-50%)",
						zIndex: 1,
					}}
				/> */}
			</Box>

			<Box
				ref={aboutRef}
				sx={{
					mt: {
						xs: 5,
						sm: 0,
					},
					px: {
						xs: 3,
						sm: 10,
					},
					pb: 10,
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
				}}>
				<SquashFacilities />
				<Box
					sx={{
						position: "relative",
						pt: {
							xs: 5,
							md: 0,
						},
					}}>
					<Divider
						sx={{
							my: 7,
						}}
					/>
					<Img
						src={logo}
						alt="logo"
						width={60}
						height={60}
						sx={{
							position: "absolute",
							bottom: {
								xs: -30,
								sm: 25,
							},
							left: "50%",
							transform: "translateX(-50%)",
							zIndex: 1,
						}}
					/>
				</Box>
				<Box py={7.5}>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "56%",
							},
							marginLeft: "auto",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<Typography level="h1">GALLERY</Typography>
						<IconButton
							variant="outlined"
							onClick={() => setImageUploadModalOpen(true)}>
							<Upload />
							Upload
						</IconButton>
					</Box>
					<Box
						sx={{
							py: 10,
						}}>
						{images.length > 0 ? (
							<ImageCarousel
								data={images}
								carouselProps={{
									slidesToShow: isMobile
										? 1
										: isTablet
										? 3
										: images.length < 5
										? images.length
										: 5,
								}}
							/>
						) : (
							<Typography textAlign={"center"}>No images found</Typography>
						)}
					</Box>
					<ImageUploadModal
						open={imageUploadModalOpen}
						onClose={() => setImageUploadModalOpen(false)}
					/>
				</Box>

				{/* <Box
					sx={{
						textAlign: "center",
						py: 7,
					}}>
					<Typography level="h1">NEWS AND EVENTS</Typography>
					<Typography level="body-sm" mt={2}>
						Nulla lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
						cillum dolor. Voluptate exercitation incididunt aliquip deserunt.
					</Typography>

					<Box
						sx={{
							// display: "flex",
							// justifyContent: "center",
							// flexWrap: "wrap",
							width: {
								xs: "100%",
								sm: 700,
							},
							mt: 5,
							marginLeft: "auto",
							marginRight: "auto",
						}}>
						<Slider
							ref={sliderRef}
							onSwipe={(swipeDirection) => {
								if (swipeDirection === "left") {
									goToSlide(currentSlide + 1);
								} else {
									goToSlide(currentSlide - 1);
								}
							}}
							{...{
								arrows: false,
								dots: false,
								infinite: true,
								speed: 500,
								slidesToShow: isMobile ? 1 : 3,
								slidesToScroll: 0,
							}}>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
								<Box
									sx={{
										mx: 1,
										textAlign: {
											xs: "center",
											sm: "initail",
										},
										width: 220,
										display: {
											xs: item === 1 ? "block" : "none",
											sm: "block",
										},
									}}>
									<Box
										sx={{
											width: 220,
											background: "#c4c4c4",
											height: 180,
											marginLeft: "auto",
											marginRight: "auto",
										}}></Box>

									<Button
										sx={{
											width: 150,
											height: 35,
											backgroundColor: "#F1A40A",
											mt: 2,
										}}>
										<Typography
											level="title-sm"
											sx={{ color: "white", fontWeight: "bold" }}>
											Launch Event
										</Typography>
									</Button>
									<Typography level="h4" mt={1}>
										Lorem ipsum dolor sit amet elit eiusmod.
									</Typography>
								</Box>
							))}
						</Slider>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mt: 5,
						}}>
						<IconButton
							onClick={() => {
								goToSlide(currentSlide - 1);
							}}
							variant="outlined">
							<ChevronLeft />
						</IconButton>
						{[1, 2, 3, 4].map((item) => (
							<Button
								onClick={() => goToSlide(isMobile ? item - 1 : item * 3 - 3)}
								variant="outlined"
								sx={{
									mx: 1,
									background: item === currentSlide + 1 ? "#F1A40A" : "white",
									color: item === currentSlide + 1 ? "white" : "black",
								}}>
								{item}
							</Button>
						))}
						<IconButton
							onClick={() => {
								goToSlide(currentSlide + 1);
							}}
							variant="outlined">
							<ChevronRight />
						</IconButton>
					</Box>
				</Box> */}
			</Box>
			<VenueFacilities />
			<Box
				sx={{
					padding: "10px 80px",
				}}>
				<FeesAndMemberships />
			</Box>
			<Programming />
			<Partners />
			{/* <NewsLetter venue={{ name: venue?.name, id: venue?.id }} /> */}
			<Footer aboutRef={aboutRef} teamRef={null} contactUsRef={contactUsRef} />

			{/* <LoginModal /> */}
		</div>
	);
}
