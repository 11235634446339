import { StateCreator, StoreApi } from "zustand";
import { IStore } from ".";

export interface ITournamentsSlice {
	tournamentCheckIns: any[];
	getTournamentCheckIns: (clubNameUnique: string) => Promise<void>;
}

export const createTournamentsSlice: StateCreator<
	IStore,
	[],
	[],
	ITournamentsSlice
> = (set: StoreApi<IStore>["setState"], get: StoreApi<IStore>["getState"]) => ({
	tournamentCheckIns: [],
	getTournamentCheckIns: async (clubNameUnique: string) => {
		const tournamentCheckIns = await get()
			.app.service("tournament-checkin")
			.find({
				query: {
					clubName: "Meadow Park Recreation Center",
					$sort: { createdAt: -1 },
				},
			});

		set({ tournamentCheckIns: tournamentCheckIns.data });
	},
});
