import { Box, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import Club from "../assets/Club.png";

const VenueFacilities = () => {
	const facilities = [
		"Hot Showers",
		"Changing Rooms",
		"Lockers",
		"Cafe",
		"Water Fountains",
		"Drinks Vending Machine",
		"Snack Vending Machine",
		"Free Parking",
		"Online Booking",
		"Permanently Staffed",
		"Defibrillator",
		"First Aid",
	];

	// Group the facilities into arrays of 4
	const facilityRows = [];
	for (let i = 0; i < facilities.length; i += 3) {
		facilityRows.push(facilities.slice(i, i + 3));
	}

	return (
		<Box
			sx={{
				width: "100%",
				background: "#1F3A6E",
				padding: 4.5,
				color: "white",
			}}>
			<SectionHeader
				title={"VENUE FACILITIES"}
				icon={Club}
				iconStyle={{
					width: 45,
				}}
				titleColor="white"
			/>

			{/* Render the facilities in 3 rows */}
			<Box
				sx={{
					mt: 2,
				}}>
				{facilityRows.map((row, rowIndex) => (
					<Box
						key={rowIndex}
						sx={{
							display: "flex",
							justifyContent: "space-around",
							marginBottom: 1,
						}}>
						{row.map((facility, index) => (
							<Box
								key={index}
								sx={{
									width: 200,
									padding: 1,
									background: "#425882",
								}}>
								<Typography
									sx={{
										color: "white",
									}}>
									{facility}
								</Typography>
							</Box>
						))}
					</Box>
				))}
			</Box>
		</Box>
	);
};
export default VenueFacilities;
