import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Input,
	Radio,
	Typography,
} from "@mui/joy";
import { ReactComponent as ArrowIcon } from "../Icons/arrow.svg";
import Footer from "../Components/Footer";

const styles: {
	[key: string]: React.CSSProperties;
} = {
	header: {
		width: "100%",
		height: 50,
		backgroundColor: "#1F3A6E",
		display: "flex",
		padding: 10,
		justifyContent: "space-between",
	},
	headerText: {
		color: "white",
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	headerLogo: {
		width: 25,
	},
	headerTitle: {
		width: 40,
		height: 40,
	},
	main: {
		height: 325,
		width: "100%",
		backgroundColor: "#9BAFBC",
		padding: "20px 60px",
		fontSize: 20,
		display: "flex",
		flexDirection: "column",
	},
	mainTitle: {
		fontSize: 40,
		fontWeight: "700",
	},
	mainAddress: {
		fontSize: 20,
	},
	content: {
		padding: "0 40px",
		height: "70vh",
	},
	textItem: {
		padding: "35px 0",
		borderBottom: "1px solid #9BAFBC",
		fontSize: 18,
		fontWeight: "700",
		color: "black",
	},
	footer: {
		height: 300,
		width: "100%",
		backgroundColor: "#1F3A6E",
	},
};

const players = [
	"Ben Thomas",
	"John Doe",
	"Jane Doe",
	"Ken Lelek",
	"Doug Wilson",
	"John Wilson",
	"Charles Stenner",
	"Jane Smith",
	"Kevin Jernigan",
	"James Smith",
	"John Smith",
	"Daryl Simpson",
];

const ClubAdmin = () => {
	const tabs = [
		"Home",
		"Blog",
		"Programming",
		"Court Booking",
		"Fees and Memberships",
		"Gallery",
	];
	return (
		<div>
			<div style={styles.header}>
				<Box sx={styles.headerText}>
					<img src={require("../assets/Back.png")} style={styles.headerLogo} />
					<Typography sx={{ color: "white" }}> Back to map</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 10,
						position: "absolute",
						left: "50%",
						transform: "translateX(-50%)",
					}}>
					{["Home", "Gallery", "Court Booking", "Blog", "Admin"].map((tab) => (
						<Typography sx={{ color: "white" }}>{tab}</Typography>
					))}
				</Box>
			</div>

			<div style={styles.main}>
				<Typography style={styles.mainTitle}>
					Meadow Park Sports Centre
				</Typography>
				<div style={styles.mainAddress}>
					8625 BC-99, Whistler BC V8E 1K1, Canada
				</div>
				<Box
					sx={{
						marginTop: "auto",
						display: "flex",
						justifyContent: "space-between",
					}}>
					<Typography sx={{ color: "black" }}>Administration site</Typography>
					{tabs.map((tab) => (
						<Typography sx={{ color: "white" }}>{tab}</Typography>
					))}
				</Box>
			</div>
			<Box
				sx={{
					p: 5,
				}}>
				<Box
					sx={{
						gap: 2,
						display: "flex",
						flexDirection: "column",
					}}>
					<Typography>
						1. To verify your connection to the “Meadow Park Recreation Centre”
						Squash program, open up another browser window and connect to your
						ClubLockr.com account. Copy and paste your venues web address below.
					</Typography>
					<Input
						placeholder="https://"
						variant="plain"
						sx={{
							width: "50%",
							background: "#d0d0d0d0",
						}}
					/>
					<Typography>
						2. Secondly, in the box below enter the name of the club management
						software you use as your squash court bookingapplication.
					</Typography>
					<Input
						variant="plain"
						sx={{
							width: "50%",
							background: "#d0d0d0d0",
						}}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						marginTop: 5,
					}}>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "black",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: 50,
						}}>
						<Typography level="h3">PROGRAMMING</Typography>
					</Box>

					<Typography>
						If you are running any programs through Club Locker, they will be
						listed below. These programs will also be listed on your home page.
						If you do not wish to list one or more of these programs, unselect
						them.
					</Typography>
					<Typography>
						Download the “ClubHub” app today. This app was designed to work with
						Club Locker to improve the player experience and increase
						participation.
					</Typography>
					<Input
						variant="plain"
						sx={{
							width: "30%",
							background: "#d0d0d0d0",
						}}
					/>

					<Accordion
						variant="plain"
						sx={{
							borderBottom: 1,
							borderColor: "#1f3a6e",
							padding: "10px 0px",
						}}>
						<AccordionSummary indicator={<ArrowIcon width={20} />}>
							<Typography level="h3">
								Beginners Clinic - Wednesday September 25
							</Typography>
						</AccordionSummary>
					</Accordion>
					<Accordion
						variant="plain"
						sx={{
							borderBottom: 1,
							borderColor: "#1f3a6e",
							padding: "10px 0px",
						}}>
						<AccordionSummary indicator={<ArrowIcon width={20} />}>
							<Typography level="h3">
								Thirsty Thursdays - Thursday September 26
							</Typography>
						</AccordionSummary>
					</Accordion>
					<Accordion
						variant="plain"
						expanded
						sx={{
							borderBottom: 1,
							borderColor: "#1f3a6e",
							padding: "10px 0px",
						}}>
						<AccordionSummary indicator={<ArrowIcon width={20} />}>
							<Typography level="h3">
								Mark Revell Memorial Tournament - Thursday September 26
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>
								Improve communication, promotion and a sense of community while
								reducing no-shows through the CLUBHUB app.
							</Typography>
							<Box display={"flex"} alignItems="center" mt={2}>
								<Box textAlign="right" width={"70%"}>
									<Typography>
										Create a social group chat for anyone interested in the
										event:
									</Typography>
									<Typography>
										Create a group chat for the athletes who have checked-in to
										the event:
									</Typography>
									<Typography>
										Send private messages to only those athletes who have
										entered but not yet checked in:
									</Typography>
								</Box>
								<Box width={"30%"} display={"flex"} flexDirection={"column"}>
									<Radio
										sx={{
											ml: "30%",
										}}
									/>
									<Radio
										sx={{
											ml: "20%",
										}}
									/>
									<Radio
										sx={{
											ml: "10%",
										}}
									/>
								</Box>
							</Box>
							<Accordion variant="plain" expanded>
								<AccordionSummary indicator={<ArrowIcon width={20} />}>
									<Typography level="h4">Mens Draw</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box display={"flex"} flexDirection={"column"} gap={2}>
										{players.map((player) => (
											<Box display={"flex"} alignItems="center">
												<Typography width={"70%"} pl={3}>
													{player}
												</Typography>
												<Box
													width={"30%"}
													display={"flex"}
													sx={{
														ml: "10%",
													}}
													gap={"8%"}>
													<Radio
														sx={
															{
																// ml: "10%",
															}
														}
													/>
													<Radio
														sx={
															{
																// ml: "20%",
															}
														}
													/>
													<Radio
														sx={
															{
																// ml: "10%",
															}
														}
													/>
												</Box>
											</Box>
										))}
										<Button
											sx={{
												width: 150,
												p: 0,
												ml: "auto",
												mr: "230px",
											}}>
											Create Chat Group
										</Button>
									</Box>
								</AccordionDetails>
							</Accordion>
							<Accordion variant="plain" sx={{ mt: 2 }}>
								<AccordionSummary indicator={<ArrowIcon width={20} />}>
									<Typography level="h4">Womens Draw</Typography>
								</AccordionSummary>
							</Accordion>
						</AccordionDetails>
					</Accordion>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							mt: 7,
							pb: 50,
						}}>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: "black",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: 50,
							}}>
							<Typography level="h3">FEES AND MEMBERSHIPS</Typography>
						</Box>
						<Typography>
							If you are running any programs through Club Locker, they will be
							listed below. These programs will also be listed on your home
							page. If you do not wish to list one or more of these programs,
							unselect them.
						</Typography>
						<Typography>
							Download the “ClubHub” app today. This app was designed to work
							with Club Locker to improve the player experience and increase
							participation.
						</Typography>
					</Box>
				</Box>
			</Box>
			<Footer
				aboutRef={undefined}
				teamRef={undefined}
				contactUsRef={undefined}
			/>
		</div>
	);
};

export default ClubAdmin;
