import "./App.css";
import { CssVarsProvider, extendTheme } from "@mui/joy";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
} from "react-router-dom";
import Home from "./routes/Home";
import Clubsites from "./routes/Clubsites";
import CourtBooking from "./routes/CourtBooking";
import FindCourts from "./routes/FindCourts";
// import Maps from "./routes/Maps";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VenuesMap from "./routes/VenuesMap";
import LoginScreen from "./admin/screens/LoginScreen";
import useAuth from "./admin/useAuth";
import AuthLayout from "./admin/components/AuthLayout";
import SidebarComponent from "./admin/components/SidebarComponent";
import InitializingScreen from "./admin/screens/InitializingScreen";
import ImagesScreen from "./admin/screens/ImagesScreeen";
import DashboardScreen from "./admin/screens/DashboardScreen";
import TextsScreen from "./admin/screens/TextsScreen";
import TournamentScreen from "./admin/screens/TournamentsScreen";
import Admin from "./routes/Admin";
import PluginCoverScreen from "./routes/PluginCover";
import ClubAdmin from "./routes/ClubAdmin";
import Blog from "./routes/Admin/Blog";
import AdminHome from "./routes/Admin/Home";

function App() {
	const theme = extendTheme({
		fontFamily: {
			body: "Nunito, sans-serif",
			display: "Nunito, sans-serif",
		},
	});

	const auth = useAuth();

	const venueName = window.location.pathname.split("/")[2];

	return (
		<CssVarsProvider theme={theme}>
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/vu/:venueName" element={<Clubsites />} />
					<Route path="/courtbooking" element={<CourtBooking />} />
					<Route path="/find-courts" element={<FindCourts />} />
					{/* <Route path="/maps" element={<Maps />} /> */}
					<Route path="/vu" element={<VenuesMap />} />
					<Route path="/admin" element={<AdminHome />} />
					<Route path="/plugincover" element={<PluginCoverScreen />} />
					<Route path="/clubadmin" element={<ClubAdmin />} />
					<Route path="/admin/blog" element={<Blog />} />
					{auth.isLoaded ? (
						<>
							{auth.isLoggedIn ? (
								<>
									<Route
										path="/vu/:venueName/admin"
										element={
											<Navigate to={`/vu/${venueName}/admin/dashboard`} />
										}
									/>
									<Route
										path="/vu/:venueName/admin/login"
										element={
											<Navigate to={`/vu/${venueName}/admin/dashboard`} />
										}
									/>
									<Route
										element={<AuthLayout sidebar={<SidebarComponent />} />}>
										<Route
											path="/vu/:venueName/admin/dashboard"
											element={<DashboardScreen />}
										/>
										<Route
											path="/vu/:venueName/admin/images"
											element={<ImagesScreen />}
										/>
										<Route
											path="/vu/:venueName/admin/texts"
											element={<TextsScreen />}
										/>
										<Route
											path="/vu/:venueName/admin/tournaments"
											element={<TournamentScreen />}
										/>
									</Route>
								</>
							) : (
								<>
									<Route
										path="*"
										element={<Navigate to={`/vu/${venueName}/admin/login`} />}
									/>
									<Route
										path={`/vu/${venueName}/admin/login`}
										element={<LoginScreen />}
									/>
								</>
							)}
						</>
					) : (
						<Route
							path={`/vu/${venueName}/admin/*`}
							element={<InitializingScreen />}
						/>
					)}
				</Routes>
			</Router>
		</CssVarsProvider>
	);
}

export default App;
