import { Box, Button, Textarea, Typography } from "@mui/joy";
import AdminHeader from "../../Components/AdminHeader";
import SectionHeader from "../../Components/SectionHeader";
import { ReactComponent as SquashPlayers } from "../../assets/squash-players.svg";
import Footer from "../../Components/Footer";

export default function AdminHome() {
	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: 7,
				}}>
				<SectionHeader title="FACILITIES" />
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}>
					<Typography width={"30%"}>
						To edit the list of venue features a description of the squash
						facilities, download the Squash Players App.
					</Typography>
					<SquashPlayers
						style={{
							width: 200,
							height: 200,
						}}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							width: "30%",
							alignItems: "center",
						}}>
						<Button sx={{ bgcolor: "#d0d0d0", width: 200 }}>
							<Typography>iPhone App Store</Typography>
						</Button>
						<Button sx={{ bgcolor: "#d0d0d0", width: 200 }}>
							<Typography>Android Play Store</Typography>
						</Button>
					</Box>
				</Box>
				<Box sx={{ mt: 10 }}>
					<SectionHeader title="BRANDING" />
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-around",
							py: 5,
						}}>
						<Box>
							<Typography textAlign={"center"} pb={2}>
								Add your venues logo
							</Typography>
							<Box
								sx={{
									width: 200,
									height: 200,
									bgcolor: "#d0d0d0",
									borderRadius: "50%",
								}}>
								<input
									type="file"
									style={{
										width: 200,
										height: 200,
										opacity: 0,
										cursor: "pointer",
										borderRadius: "50%",
									}}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}>
							<Typography>
								Add a brief description or slogan for your club
							</Typography>
							<Textarea sx={{ height: 200 }} />
							<Button sx={{ width: 150, ml: "auto" }}>Submit</Button>
						</Box>
					</Box>
				</Box>
				<Box>
					<SectionHeader title="HOUSE OF OPERATION" />
					<Box></Box>
					<Box></Box>
					<Box></Box>
				</Box>
			</Box>
			<Footer
				aboutRef={undefined}
				teamRef={undefined}
				contactUsRef={undefined}
			/>
		</div>
	);
}
