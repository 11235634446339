import { Box, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import Club from "../assets/Club.png";

const Programming = () => {
	return (
		<Box
			sx={{
				background: "#9BAFBC",
				padding: "25px 80px",
			}}>
			<SectionHeader
				title={"PROGRAMMING"}
				titleColor="white"
				icon={Club}
				iconStyle={{
					width: 45,
				}}
			/>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: 1,
					marginTop: 2,
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}>
					<Box
						sx={{
							width: 450,
							height: 40,
							backgroundColor: "#AFBFC9",
							display: "flex",
							alignItems: "center",
							pl: 2,
						}}>
						<Typography
							sx={{
								color: "white",
								fontWeight: "bold",
							}}>
							Squash League
						</Typography>
					</Box>
					<Box
						sx={{
							width: 450,
							height: 40,
							backgroundColor: "#AFBFC9",
							display: "flex",
							alignItems: "center",
							pl: 2,
						}}>
						<Typography
							sx={{
								color: "white",
								fontWeight: "bold",
							}}>
							Thristy Thursday Drop-In
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 2,
					}}>
					<Box
						sx={{
							width: 450,
							height: 40,
							backgroundColor: "#AFBFC9",
							display: "flex",
							alignItems: "center",
							pl: 2,
						}}>
						<Typography
							sx={{
								color: "white",
								fontWeight: "bold",
							}}>
							Squash League
						</Typography>
					</Box>
					<Box
						sx={{
							width: 450,
							height: 40,
							backgroundColor: "#AFBFC9",
							display: "flex",
							alignItems: "center",
							pl: 2,
						}}>
						<Typography
							sx={{
								color: "white",
								fontWeight: "bold",
							}}>
							Thristy Thursday Drop-In
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						height: 40,
						backgroundColor: "#AFBFC9",
						display: "flex",
						alignItems: "center",
						p: "0 20px",
						mt: 1,
					}}>
					<Typography
						sx={{
							color: "#398EF5",
							fontWeight: "bold",
						}}>
						Participate by downloading our Networking app
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default Programming;
