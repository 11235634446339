import { FunctionComponent, useEffect, useState } from "react";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import useStore from "../Stores";
import { Avatar, Box, CircularProgress, Table } from "@mui/joy";
import axios from "axios";

interface TournamentScreenProps {}

const TournamentScreen: FunctionComponent<TournamentScreenProps> = () => {
	const store = useStore();
	const [checkIns, setCheckIns] = useState<any>({});
	const [tournamentPlayers, setTournamentPlayers] = useState<any>({});
	const [selectedTournament, setSelectedTournament] = useState<string>("");

	useEffect(() => {
		store.getTournamentCheckIns("meadowparksportscentre");
	}, []);

	useEffect(() => {});

	useEffect(() => {
		if (store.tournamentCheckIns.length !== 0) {
			mapCheckInsByTournament();
		}
	}, [store.tournamentCheckIns]);

	const mapCheckInsByTournament = () => {
		const checkInsByTournament: any = {};
		store.tournamentCheckIns.forEach((checkIn: any) => {
			if (!checkInsByTournament[checkIn.tournamentName]) {
				checkInsByTournament[checkIn.tournamentName] = [];
			}
			checkInsByTournament[checkIn.tournamentName].push(checkIn);
		});

		setCheckIns(checkInsByTournament);
	};

	const getTorunamentPlayers = async (tournamentName: string) => {
		const tournamentId = checkIns[tournamentName][0].tournamentId;
		const { data } = await axios.get(
			`https://api.ussquash.com/resources/tournaments/${tournamentId}/players-positions?`
		);
		const players = Object.values(data)
			.map((tier: any) => tier.players)
			// @ts-ignore
			.flat();

		// map checkIns to players

		const checkInPlayers = players.map((player: any) => {
			const checkIn = checkIns[tournamentName].find(
				(checkIn: any) => checkIn.user.clubLockerUserId === player.PlayerId
			);
			return {
				...player,
				checkedIn: !!checkIn,
			};
		});

		setTournamentPlayers({
			...tournamentPlayers,
			[tournamentName]: checkInPlayers,
		});
	};

	console.log(tournamentPlayers);
	return (
		<div>
			<AccordionGroup>
				{Object.keys(checkIns).map((tournamentName: string) => {
					return (
						<Accordion
							key={tournamentName}
							onChange={() => {
								if (!tournamentPlayers[tournamentName])
									getTorunamentPlayers(tournamentName);
							}}>
							<AccordionSummary>{tournamentName}</AccordionSummary>
							<AccordionDetails>
								{tournamentPlayers[tournamentName] ? (
									<Table>
										<thead>
											<tr style={{ textAlign: "left" }}>
												<th>Player Name</th>
												<th>Clublocker ID</th>
												<th>Checked In</th>
											</tr>
										</thead>
										{tournamentPlayers[tournamentName].map((player: any) => {
											return (
												<tbody>
													<tr>
														<td>{player.PlayerName}</td>
														<td>{player.PlayerId}</td>
														<td
															style={{
																fontWeight: player.checkedIn
																	? "bold"
																	: "normal",
															}}>
															{player.checkedIn ? "True" : "False"}
														</td>
													</tr>
												</tbody>
											);
										})}
									</Table>
								) : (
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
										}}>
										<CircularProgress />
									</Box>
								)}
							</AccordionDetails>
						</Accordion>
					);
				})}
			</AccordionGroup>
		</div>
	);
};

export default TournamentScreen;
