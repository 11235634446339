import React from "react";
import { Box, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import squashCourtIcon from "../assets/Squash Court.png";

const facilities = {
	category: ["Leisure Centre"],
	"Members Only": ["No"],
	"Guests Welcome": ["Yes"],
	"Pay & Play": ["Yes"],
	courts: {
		"Glass Back": 1,
		Enclosed: 1,
	},
};

const styles = {
	container: {
		height: 300,
	},
	headerIcon: {
		width: 35,
	},
	mainBox: {
		mt: 2,
		display: "flex",
		gap: 1,
	},
	columnBox: {
		display: "flex",
		flexDirection: "column",
		gap: 1,
	},
	itemBox: {
		width: 300,
		padding: 1,
		background: "#D0d0d0",
		height: 50,
	},
	courtsBox: {
		display: "flex",
	},
	courtItemBox: {
		width: 200,
		height: 50,
		padding: 1,
	},
};

const SquashFacilities = () => {
	return (
		<Box sx={styles.container}>
			<SectionHeader
				icon={squashCourtIcon}
				iconStyle={styles.headerIcon}
				title={"Squash Facilities"}
			/>
			<Box sx={styles.mainBox}>
				<Box sx={styles.columnBox}>
					<Box sx={styles.itemBox}>
						<Typography>CATEGORY</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>MEMBERS ONLY</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>GUESTS WELCOME</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>PAY & PLAY</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>COURTS</Typography>
					</Box>
				</Box>
				<Box sx={{ ...styles.columnBox, justifyContent: "center" }}>
					<Box sx={styles.itemBox}>
						<Typography>LEISURE CENTRE</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>YES</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>YES</Typography>
					</Box>
					<Box sx={styles.itemBox}>
						<Typography>YES</Typography>
					</Box>
					<Box sx={styles.courtsBox}>
						<Box sx={styles.courtItemBox}>
							<Typography>2 Total</Typography>
						</Box>
						<Box sx={styles.courtItemBox}>
							<Typography>1 Glass Back</Typography>
						</Box>
						<Box sx={styles.courtItemBox}>
							<Typography>1 Enforced</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default SquashFacilities;
