import {
	Box,
	Dropdown,
	FormLabel,
	Input,
	Select,
	Textarea,
	Typography,
} from "@mui/joy";
import AdminHeader from "../../Components/AdminHeader";
import { ReactComponent as ArrowIcon } from "../../Icons/arrow.svg";
import Footer from "../../Components/Footer";

const Blog = () => {
	return (
		<div>
			<AdminHeader />
			<Box
				sx={{
					p: 7,
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "black",
						display: "flex",
						alignItems: "center",
						height: 50,
					}}>
					<Typography
						level="h3"
						sx={{
							position: "absolute",
							left: "50%",
							transform: "translateX(-50%)",
						}}>
						ADD A NEW BLOG POST
					</Typography>
					<ArrowIcon
						width={20}
						style={{
							marginLeft: "auto",
							marginRight: "10px",
						}}
					/>
				</Box>

				<Typography>All fields are required</Typography>
				<Input placeholder="Blog Title" />
				<Textarea
					placeholder="Blog Content"
					sx={{
						height: 200,
					}}
				/>
				<FormLabel>Photo</FormLabel>
				<Input type="file" sx={{ width: 300 }} />
				<FormLabel>(Optional) Select a location for the post</FormLabel>
				<Input placeholder="Location" sx={{ width: 300 }} />
				<FormLabel>
					(Optional) Select one event from one of the following Club Locker for
					the post
				</FormLabel>
				<Box
					sx={{
						display: "flex",
						gap: 5,
					}}>
					<Select
						placeholder="Tag Event"
						indicator={<ArrowIcon width={16} height={16} />}></Select>
					<Select
						placeholder="Tag Box League"
						indicator={<ArrowIcon width={16} height={16} />}></Select>
					<Select
						placeholder="Tag Teams League"
						indicator={<ArrowIcon width={16} height={16} />}></Select>
					<Select
						placeholder="Tag Membership"
						indicator={<ArrowIcon width={16} height={16} />}></Select>
				</Box>
			</Box>
			<Footer
				aboutRef={undefined}
				teamRef={undefined}
				contactUsRef={undefined}
			/>
		</div>
	);
};

export default Blog;
