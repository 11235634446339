import { Box, Typography } from "@mui/joy";

const styles: {
	[key: string]: React.CSSProperties;
} = {
	header: {
		width: "100%",
		height: 50,
		backgroundColor: "#1F3A6E",
		display: "flex",
		padding: 10,
		justifyContent: "space-between",
	},
	headerText: {
		color: "white",
		display: "flex",
		alignItems: "center",
		gap: 2,
	},
	headerLogo: {
		width: 25,
	},
	headerTitle: {
		width: 40,
		height: 40,
	},
	main: {
		height: 325,
		width: "100%",
		backgroundColor: "#9BAFBC",
		padding: "20px 60px",
		fontSize: 20,
		display: "flex",
		flexDirection: "column",
	},
	mainTitle: {
		fontSize: 40,
		fontWeight: "700",
	},
	mainAddress: {
		fontSize: 20,
	},
};
const tabs = [
	"Home",
	"Blog",
	"Programming",
	"Court Booking",
	"Fees and Memberships",
	"Gallery",
];

export default function AdminHeader() {
	return (
		<div>
			<div style={styles.header}>
				<Box sx={styles.headerText}>
					<img src={require("../assets/Back.png")} style={styles.headerLogo} />
					<Typography sx={{ color: "white" }}> Back to map</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 10,
						position: "absolute",
						left: "50%",
						transform: "translateX(-50%)",
					}}>
					{["Home", "Gallery", "Court Booking", "Blog", "Admin"].map((tab) => (
						<Typography sx={{ color: "white" }}>{tab}</Typography>
					))}
				</Box>
			</div>

			<div style={styles.main}>
				<Typography style={styles.mainTitle}>
					Meadow Park Sports Centre
				</Typography>
				<div style={styles.mainAddress}>
					8625 BC-99, Whistler BC V8E 1K1, Canada
				</div>
				<Box
					sx={{
						marginTop: "auto",
						display: "flex",
						justifyContent: "space-between",
					}}>
					<Typography sx={{ color: "black" }}>Administration site</Typography>
					{tabs.map((tab) => (
						<Typography sx={{ color: "white" }}>{tab}</Typography>
					))}
				</Box>
			</div>
		</div>
	);
}
