import { Box, Button, Typography } from "@mui/joy";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

interface FooterProps {
	aboutRef: any;
	teamRef: any;
	contactUsRef: any;
}

export default function Footer(props: FooterProps) {
	const navigate = useNavigate();
	const scrollTo = (ref: any) => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					height: 10,
					backgroundColor: "#E79301",
				}}
			/>
			<Box
				sx={{
					backgroundColor: "#1f3a6e",
					display: {
						xs: "block",
						md: "flex",
					},
					justifyContent: "space-evenly",
					py: 10,
					px: 10,
				}}>
				<Box
					sx={{
						maxWidth: 300,
						textAlign: "center",
					}}>
					<img
						src={logo}
						alt="logo"
						width={90}
						height={90}
						style={{ marginLeft: "auto", marginRight: "auto" }}
					/>
					<Typography sx={{ color: "white", mt: 1 }}>
						ClubHub supports today’s top club software providers integrated into
						a single social network to bring together the entire squash
						community like never before.
					</Typography>
				</Box>
				<Box
					sx={{
						mt: {
							xs: 2,
							md: 0,
						},
					}}>
					<Typography level="h3" sx={{ color: "white" }}>
						HEAD OFFICE
					</Typography>
					<Typography sx={{ color: "white" }}>
						8621 Fissile Ln Whistler, British Columbia V0N 1B8, CA
					</Typography>
					{/* <Typography sx={{ color: "white" }}>
						deanna.curtis@example.com
					</Typography>
					<Typography sx={{ color: "white" }}>+1 (234) 567 8901</Typography> */}
				</Box>
				<Box
					sx={{
						mt: {
							xs: 2,
							md: 0,
						},
					}}>
					<Typography level="h3" sx={{ color: "white" }}>
						USEFUL LINKS
					</Typography>
					<Typography
						onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
						sx={{ color: "white", cursor: "pointer" }}>
						Home
					</Typography>
					<Typography
						onClick={() => scrollTo(props.aboutRef)}
						sx={{ color: "white", cursor: "pointer" }}>
						About
					</Typography>
					{/* <Typography
						onClick={() => scrollTo(props.teamRef)}
						sx={{ color: "white", cursor: "pointer" }}>
						Team
					</Typography> */}
					<Typography
						onClick={() => scrollTo(props.contactUsRef)}
						sx={{ color: "white", cursor: "pointer" }}>
						Contact Us
					</Typography>
				</Box>
				<Box
					sx={{
						mt: {
							xs: 2,
							md: 0,
						},
					}}>
					<Typography level="h3" sx={{ color: "white" }}>
						OPENING HOUR
					</Typography>
					<Typography sx={{ color: "white" }}>Monday - Friday</Typography>
					<Typography sx={{ color: "white" }}>9:00 AM - 8:00 PM</Typography>
				</Box>
			</Box>
		</Box>
	);
}
