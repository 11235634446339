import { Box, Table, Typography } from "@mui/joy";
import React from "react";
import SectionHeader from "./SectionHeader";

const FeesAndMemberships = () => {
	const feesData = {
		dropInFees: [
			{ type: "Adult/Senior (19+)", dropIn: "$10.00", allDay: "$15.00" },
			{ type: "Youth (13 - 18)", dropIn: "$6.00", allDay: "$9.00" },
			{ type: "Child (4 - 12)", dropIn: "$5.00", allDay: "$7.50" },
			{ type: "Family", dropIn: "$20.00", allDay: "$30.00" },
		],
		multiTimePasses: [
			{
				type: "Adult/Senior (19+)",
				"10xPass": "$84.00",
				"20xPass": "$148.00",
				"30xPass": "$197.50",
			},
			{
				type: "Youth (13 - 18)",
				"10xPass": "$50.50",
				"20xPass": "$89.00",
				"30xPass": "$118.50",
			},
			{
				type: "Child (4 - 12)",
				"10xPass": "$42.00",
				"20xPass": "$74.00",
				"30xPass": "$98.75",
			},
			{
				type: "Family",
				"10xPass": "$168.00",
				"20xPass": "$296.00",
				"30xPass": "$395.00",
			},
		],
		monthlyPasses: [
			{
				type: "Adult/Senior (19+)",
				monthly: "$90.00",
				"3Month": "$215.00",
				"6Month": "$360.00",
				annual: "$610.00",
			},
			{
				type: "Youth (13 - 18)",
				monthly: "$54.00",
				"3Month": "$129.00",
				"6Month": "$216.00",
				annual: "$366.00",
			},
			{
				type: "Child (4 - 12)",
				monthly: "$45.00",
				"3Month": "$107.50",
				"6Month": "$180.00",
				annual: "$305.00",
			},
			{
				type: "Family",
				monthly: "$180.00",
				"3Month": "$430.00",
				"6Month": "$720.00",
				annual: "$1220.00",
			},
			{ type: "Off-Peak Pass (Adult)", monthly: "$67.50", "3Month": "$161.25" },
			{ type: "Off-Peak Pass (Youth)", monthly: "$40.50", "3Month": "$96.75" },
		],
	};

	const renderTable = (title: string, data: any[], columns: any[]) => (
		<Box sx={{ marginTop: 1 }}>
			<Table
				borderAxis="none"
				variant="plain"
				sx={{
					width: "auto",
				}}>
				<thead
					style={{
						backgroundColor: "white",
					}}>
					<tr
						style={{
							backgroundColor: "white",
						}}>
						<th
							style={{
								width: 280,
								backgroundColor: "white",
							}}>
							{title}
						</th>
						{columns.map((col, idx) => (
							<th
								key={idx}
								style={{
									width: 220,
								}}>
								{col}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((item, index) => (
						<tr key={index}>
							<td>
								<Box
									sx={{
										background: "#D0d0d0",
										width: 250,
										padding: 1,
									}}>
									{item.type}
								</Box>
							</td>
							{columns.map((col, idx) => (
								<td key={idx}>
									<Box sx={{ width: 220, padding: 1 }}>{item[col]}</Box>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
		</Box>
	);

	return (
		<Box
			sx={{
				mt: 5,
			}}>
			<SectionHeader title={"FEES AND MEMBERSHIPS"} />
			<Box display={"flex"} justifyContent={"center"} mb={4} mt={1}>
				<Typography width={"60%"} level="body-xs">
					This is crowd sourced data and may not be current or correct. You may
					wish to visit the venues official website for up-to-date information.
					If you are an administrator of this facility, please claim it to
					ensure that the content being displayed is correct and current
				</Typography>
			</Box>
			{renderTable("Drop-in Fees", feesData.dropInFees, ["dropIn", "allDay"])}
			{renderTable("Multi-Time Passes", feesData.multiTimePasses, [
				"10xPass",
				"20xPass",
				"30xPass",
			])}
			{renderTable("Monthly Passes", feesData.monthlyPasses, [
				"monthly",
				"3Month",
				"6Month",
				"annual",
			])}
		</Box>
	);
};

export default FeesAndMemberships;
