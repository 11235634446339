import React, { CSSProperties } from "react";
import squashCourtIcon from "../assets/Squash Court.png";
import { Box, Typography } from "@mui/joy";

const styles = {
	squashCourtHeader: {
		borderBottom: 1,
		borderColor: "black",
		display: "flex",
		gap: 2,
		pb: 2,
		textAlign: "center",
		alignItems: "center",
	},
};

interface SectionHeaderProps {
	icon?: any;
	iconStyle?: CSSProperties;
	title: string;
	titleColor?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
	icon,
	iconStyle,
	title,
	titleColor = "black",
}) => {
	return (
		<Box sx={styles.squashCourtHeader}>
			<img src={icon} alt="" style={iconStyle} />
			<Typography
				level={"h2"}
				sx={{
					textAlign: "center",
					color: titleColor,
					position: "absolute",
					left: "50%",
					transform: "translateX(-50%)",
				}}>
				{title}
			</Typography>
		</Box>
	);
};

export default SectionHeader;
